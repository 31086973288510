<template>
    <div>
        <b-container class="pt-5 pb-5">
            <div class="text-center">
                <img alt="Tixgo" src="../assets/logo.svg" class="mb-4" width="120" />
            </div>
            <b-card no-body class="small-card">
                <b-card-body>
                    <ValidationObserver ref="form1" slim>
                        <template v-if="has_success">
                            <div class="text-center mb-4">
                                <font-awesome-icon class="text-primary mb-3" icon="fa-solid fa-check" size="5x" />
                                <h1 class="fs-3 fs-light mb-3">Wachtwoord hersteld</h1>
                            </div>
                            <div class="m-auto text-center text-muted" style="max-width:300px;">
                                <p>Uw wachtwoord is zojuist hersteld. Klik op de onderstaande knop om in te loggen.</p>
                                <div class="mb-3">
                                    <b-button to="/account/login" variant="primary">Ga verder naar inloggen</b-button>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <form @submit.prevent="resetPassword" novalidate="novalidate">
                                <div class="text-center mb-4">
                                    <h1 class="fs-light">Wachtwoord Wijzigen</h1>
                                    <div>
                                        Reeds hersteld?
                                        <router-link to="/account/login" class="link-primary font-weight-bolder">
                                            Inloggen op je Account
                                        </router-link>
                                    </div>
                                </div>

                                <b-alert variant="danger" show v-if="has_error">
                                    <p>Er is helaas iets fout gegaan. Probeer het opnieuw of vraag een nieuwe herstel e-mail aan.</p>
                                    <b-button to="/account/recovery" variant="danger">Opnieuw herstellen</b-button>
                                </b-alert>

                                <ValidationProvider vid="password" rules="required|min:8" v-slot="{ errors }">
                                    <div class="mb-4">
                                        <label>Wachtwoord</label>
                                        <b-form-input type="password" v-model="form.password" placeholder="●●●●●●●●●" :class="errors.length > 0 ? 'is-invalid' : null" />
                                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                    </div>
                                </ValidationProvider>

                                <ValidationProvider rules="confirmed:password" v-slot="{ errors }">
                                    <div class="mb-4">
                                        <label>Wachtwoord Herhalen</label>
                                        <b-form-input type="password" v-model="form.password_repeat" placeholder="●●●●●●●●●" :class="errors.length > 0 ? 'is-invalid' : null" />
                                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                    </div>
                                </ValidationProvider>

                                <div class="text-center mb-3">
                                    <b-button variant="primary" @click="resetPassword()" size="lg" block>
                                        Wijzig wachtwoord
                                    </b-button>
                                </div>
                            </form>
                        </template>
                    </ValidationObserver>
                </b-card-body>
            </b-card>
        </b-container>
    </div>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate';

    export default ({
        components: {
            ValidationObserver,
            ValidationProvider,
        },
        metaInfo: {
            title: 'Wachtwoord Wijzigen',
        },
        data() {
            return {
                has_success: false,
                has_error: false,
                form: {
                    password: '',
                    password_repeat: '',
                    token: '',
                    username: '',
                }
            }
        },
        methods: {
            async resetPassword() {

                this.has_error = false;

                let check = await this.$refs['form1'].validate();

                if (!check) {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                    
                    return;
                }

                this.$axios.post("https://api.tixgo.nl/accounts/reset", this.form)
                    .then( response => {
                        if (response.data.status == 'success') {
                            this.has_success = true;
                        } else {
                            this.has_error = true;
                        }
                    }
                );
            }
        },
        created() {
            if (this.$route.query.token && this.$route.query.email) {
                this.form.token = this.$route.query.token;
                this.form.username = this.$route.query.email;
            }
        }
    });
</script>

<style scoped>
    .small-card{
        max-width:90%;
        margin:auto;
    }

    @media screen and (min-width:600px) {
        .small-card{
            max-width:500px;
        }
    }
</style>